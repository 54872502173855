import { STATUS_CLOSED, STATUS_FROZEN, STATUS_OPEN } from '../constants/assignment-status';
import {
    ASSIGNMENT_DETAILS,
    UPDATE_ASSIGNMENT,
    CLOSE_ASSIGNMENT,
    CLOSE_ASSIGNMENT_SILENT,
    DELETE_ASSIGNMENT,
    FREEZE_ASSIGNMENT,
    OPEN_ASSIGNMENT,
    VIEW_ASSIGNMENT,
    PUSH_ASSIGNMENT,
    RESPOND_TO_ASSIGNMENT,
    SHARE_PROPOSALS,
    MESSAGE_CLIENT,
    VIEW_CLIENT_PROFILE
} from '~/constants/assignment-actions';
import { getLoggedInUser, isClient, isAdmin, isCommunityManager, isLawyer, isSupplier } from '~/helpers/user';
import Store from '~/store/store';
import i18n from '~/services/i18n/i18n';

export function getActions(assignment, allowedActions = []) {
    if (!assignment) {
        return [];
    }

    const loggedInUser = getLoggedInUser(),
        loggedInAsLawyer = isLawyer(loggedInUser),
        loggedinAsSupplier = isSupplier(loggedInUser),
        loggedInAsClient = isClient(loggedInUser),
        loggedInAsAdmin = isAdmin(loggedInUser),
        loggedInAsCommunityManager = isCommunityManager(loggedInUser),
        isOwnAssignment = assignment.user?.id === loggedInUser.id,
        actions = [];

    if (
        assignment.status !== 'concept' &&
        (assignment.isPushed === false || (assignment.isPushed === true && (loggedInAsClient || loggedInAsAdmin || loggedInAsCommunityManager))) &&
        allowedActions.includes(VIEW_ASSIGNMENT)
    ) {
        actions.push(VIEW_ASSIGNMENT);
    }

    if (assignment.isPushed && (loggedInAsLawyer || loggedinAsSupplier)) {
        actions.push(RESPOND_TO_ASSIGNMENT);
    }

    if (allowedActions.includes(ASSIGNMENT_DETAILS)) {
        actions.push(ASSIGNMENT_DETAILS);
    }

    if (loggedInAsClient && assignment.status !== 'open' && assignment.status !== 'concept' && allowedActions.includes(OPEN_ASSIGNMENT)) {
        actions.push(OPEN_ASSIGNMENT);
    }

    if (
        loggedInAsClient &&
        assignment.status !== 'concept' &&
        allowedActions.includes(UPDATE_ASSIGNMENT)
    ) {
        actions.push(UPDATE_ASSIGNMENT);
    }

    if (loggedInAsClient && (assignment.status !== 'frozen' && assignment.status !== 'concept') && allowedActions.includes(FREEZE_ASSIGNMENT)) {
        actions.push(FREEZE_ASSIGNMENT);
    }

    if ((loggedInAsAdmin || loggedInAsCommunityManager) && (assignment.status !== 'closed' && assignment.status !== 'concept') && allowedActions.includes(CLOSE_ASSIGNMENT_SILENT)) {
        actions.push(CLOSE_ASSIGNMENT_SILENT);
    }

    if (loggedInAsClient && (assignment.status !== 'closed' && assignment.status !== 'concept') && allowedActions.includes(CLOSE_ASSIGNMENT)) {
        actions.push(CLOSE_ASSIGNMENT);
    }

    if (loggedInAsClient && isOwnAssignment && (assignment.status === 'closed' || assignment.status === 'concept') && allowedActions.includes(DELETE_ASSIGNMENT)) {
        actions.push(DELETE_ASSIGNMENT);
    }

    if ((loggedInAsAdmin || loggedInAsCommunityManager) && assignment.status === 'open' && allowedActions.includes(PUSH_ASSIGNMENT)) {
        actions.push(PUSH_ASSIGNMENT);
    }

    if ((loggedInAsAdmin || loggedInAsCommunityManager || loggedInAsClient) && assignment.status === 'open' && allowedActions.includes(SHARE_PROPOSALS)) {
        actions.push(SHARE_PROPOSALS);
    }

    if ((loggedInAsAdmin || loggedInAsCommunityManager) && allowedActions.includes(MESSAGE_CLIENT)) {
        actions.push(MESSAGE_CLIENT);
    }

    if ((loggedInAsAdmin || loggedInAsCommunityManager) && allowedActions.includes(VIEW_CLIENT_PROFILE)) {
        actions.push(VIEW_CLIENT_PROFILE);
    }

    return actions;
}

export function mapActionNameToStatus(actionName) {
    switch (actionName) {
    case OPEN_ASSIGNMENT:
        return STATUS_OPEN;
    case FREEZE_ASSIGNMENT:
        return STATUS_FROZEN;
    case CLOSE_ASSIGNMENT_SILENT:
        return STATUS_CLOSED;
    case CLOSE_ASSIGNMENT:
        return STATUS_CLOSED;
    default:
        return null;
    }
}

export function setAssignmentStatus(assignmentId, action, sendNotification) {
    const confirmed = confirm(i18n.t(`assignment.confirm.${action}`)),
        status = mapActionNameToStatus(action);

    // Enforce sendNotification to false for silent status name
    if (action === CLOSE_ASSIGNMENT_SILENT) {
        sendNotification = false;
    }

    return new Promise((resolve, reject) => {
        if (!confirmed || !assignmentId || !status) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject('Cancelled');
        }

        Store.dispatch('assignments/setStatus', { status, id: assignmentId, sendNotification }).then((res) => {
            Store.dispatch('toast/add', {
                content: i18n.t(`assignment.toast.${action}-success`)
            });

            // Prevent the sudden update of the assignment. This delay
            // gives the user a moment to grasp what's happening.
            // This is especially important for the assignment-card component.
            setTimeout(() => {
                Store.commit('assignments/assignment', res.data);
            }, 2000);

            resolve(res);
        }).catch((err) => {
            Store.dispatch('toast/add', {
                content: i18n.t(`assignment.toast.${action}-fail`),
                severity: 'error'
            });

            reject(err);
        });
    });
}

export function deleteAssignment(assignmentId) {
    const confirmed = confirm(i18n.t('assignment.confirm.delete-assignment'));

    if (!confirmed || !assignmentId) {
        return false;
    }

    return new Promise((resolve, reject) => {
        Store.dispatch('assignments/delete', assignmentId).then((res) => {
            Store.dispatch('toast/add', {
                content: i18n.t('assignment.toast.delete-assignment-success')
            });

            // Prevent the sudden update of the assignment. This delay
            // gives the user a moment to grasp what's happening.
            // This is especially important for the assignment-card component.
            setTimeout(() => {
                Store.commit('assignments/deleteAssignment', assignmentId);
            }, 2000);

            resolve(res);
        }).catch((err) => {
            Store.dispatch('toast/add', {
                content: i18n.t('assignment.toast.delete-assignment-fail'),
                severity: 'error'
            });

            reject(err);
        });
    });
}

export function pushAssignment(assignment) {
    if (assignment) {
        Store.dispatch('modal/openModal', {
            name: 'assignment-push',
            data: { assignment }
        });
    }
}

export function shareProposals(assignment) {
    if (assignment) {
        Store.dispatch('modal/openModal', {
            name: 'proposals-share',
            data: { assignment }
        });
    }
}

export function formatActionLabel(action) {
    return i18n.t(`actions.${action}`);
}
