'use strict';

import Axios from 'axios';

const axios = Axios.create({
    baseURL: window.appConfig.api.url,
    timeout: 30000, // This should equal server ex. time
    headers: {}
});

export function addBoundInterceptors(VueInstance) {
    axios.interceptors.response.use(
        function(response) {
            return response;
        },
        function(error) {
            if (error.response && error.response.status === 403) {
                // Do something generic when an error occurs
                if (
                    error.response.data.errors[0].title === 'LLQ\\Exceptions\\Terms\\TermsNotAcceptedException' ||
                    error.response.data.errors[0].title === 'The current user has not accepted the most recent terms.'
                ) {
                    VueInstance.$store.dispatch('toast/add', VueInstance.$i18n.t('terms.updated'));
                    VueInstance.$store.dispatch('auth/forceNewTerms');
                }
            }

            // if (error.response && error.response.status === 403) {

            // }

            return Promise.reject(error);
        }
    );
}

export default axios;
