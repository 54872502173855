<template>
    <span
        class="icon"
        :data-name="icon"
    >
        <component
            :is="iconComponent"
        />
    </span>
</template>

<script>
import { kebabCase } from 'lodash-es';

export default {
    props: ['icon'],

    data() {
        return {
            icons: this.importIcons()
        };
    },

    computed: {
        iconComponent() {
            return this.icons[this.icon];
        }
    },

    methods: {
        /**
         * Glob-based icon loading
         */
        importIcons: function() {
            function getFileNameFromPath(path) {
                return path
                    .replace(/^(?:.*)(?:\/assets\/img\/icons\/)(.+)\.svg$/i, '$1')
                    .replace('/', '-');
            }

            // Redefine bash path, can only use literals
            const iconImports = import.meta.glob(
                '@/assets/img/icons/**/*.svg',
                {
                    query: 'component=true&lang.svg',
                    import: 'default',
                }
            );

            const imported = {};
            Object.keys(iconImports).forEach((path) => {
                const iconName = kebabCase(getFileNameFromPath(path));
                imported[iconName] = iconImports[path];
            });
            return imported;
        }
    }
};
</script>

<style lang="less">
@import './icon.less';
</style>
