'use strict';
import 'vite/modulepreload-polyfill';

/**
 * General
 */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import smoothscroll from 'smoothscroll-polyfill';

/**
 * Vue plugins
 */
import Vue from 'vue';
import FloatingVue from 'floating-vue';
import vueSmoothScroll from 'vue2-smooth-scroll';
import { sync } from 'vuex-router-sync';
import VueLazyload from 'vue-lazyload';
import VeeValidate from 'vee-validate';
import VueGtm from '@gtm-support/vue2-gtm';
import VueAxios from 'vue-axios';
import VueContentPlaceholders from 'vue-content-placeholders';

import GraphQLQueryError from '@digitalnatives/graphql-client/src/functions/enhancedError.js';
import Api, { addBoundInterceptors } from './api/api';
import getConfig from './config/config';
import { Auth0Plugin } from './plugins/auth0';
import App from '~/app.vue';
import Router from '~/router/router';
import Store from '~/store/store';

import ValidationDictionary from '~/services/validation/dictionary';

import EventBus from '~/services/event-bus/bus';
import i18n from '~/services/i18n/i18n';
// import AuthService from '~/services/auth/auth';
import Loader from '~/services/loader/loader';
import VueTagManagerEvents from '~/services/tag-manager-events/tag-manager-events';
import GraphqlPlugin from '~/plugins/graphql.js';

/**
 * Vue components
 */
import SvgIcon from '~/patterns/atoms/icon/icon.vue';
import DefaultView from '~/patterns/templates/default-view/default-view.vue';
import FrameView from '~/patterns/templates/frame-view/frame-view.vue';
import AsideView from '~/patterns/templates/aside-view/aside-view.vue';
import SplitView from '~/patterns/templates/split-view/split-view.vue';

/**
 * Vue filters
 */
import centsToEuros from '~/filters/cents-to-euros';
import truncate from '~/filters/truncate';
import { isAdmin, isClient, isGuest, isCommunityManager, isLawyer, isSupplier } from '~/helpers/user';

getConfig().then((config) => {
    /** Bugsnag ALWAYS KEEP FIRST **/
    if (!import.meta.env.DEV) {
        Bugsnag.start({
            apiKey: config.bugsnagId,
            plugins: [new BugsnagPluginVue(Vue)],
            releaseStage: import.meta.env.MODE
        });
    }

    // kick off the smoothscroll polyfill!
    smoothscroll.polyfill();

    /**
     * Vue enhancements
     */
    sync(Store, Router); // https://github.com/vuejs/vuex-router-sync
    Vue.use(vueSmoothScroll);
    Vue.use(VueAxios, Api);
    Vue.use(GraphqlPlugin, {
        callbacks: {
            error(data) {
                if (data.errors?.some((error) => {
                    return error.message === 'The current user has not accepted the most recent terms';
                })) {
                    this.$store.dispatch('toast/add', i18n.t('terms.updated'));
                    this.$store.dispatch('auth/forceNewTerms');
                } else {
                    throw new GraphQLQueryError('Failed executing GraphQL query', data.errors);
                }
            }
        }
    });
    Vue.use(VueLazyload, {
        observer: true
    });

    Vue.use(VeeValidate, {
        i18n,
        dictionary: ValidationDictionary,
        classes: true
    });

    Vue.use(VueGtm, {
        id: config.gtmId, // Your GTM ID
        enabled: true,
        debug: import.meta.env.MODE !== 'production',
        vueRouter: Router
    });

    Vue.use(FloatingVue);

    /**
     * Auth components
     */

    // Install the authentication plugin here
    Vue.use(Auth0Plugin, {
        domain: config.auth0.domain,
        clientId: config.auth0.client_id,
        authorizationParams: {
            audience: window.appConfig.auth0.audience,
            // eslint-disable-next-line camelcase
            redirect_uri: `${window.location.origin}/login`,
        }
    });

    Vue.use(EventBus);
    Vue.use(Loader);
    Vue.use(VueTagManagerEvents);
    Vue.use(VueContentPlaceholders);

    /** Global components **/
    Vue.component('SvgIcon', SvgIcon);

    Vue.component('DefaultView', DefaultView);
    Vue.component('FrameView', FrameView);
    Vue.component('AsideView', AsideView);
    Vue.component('SplitView', SplitView);

    Vue.filter('truncate', truncate);
    Vue.filter('centsToEuros', centsToEuros);

    /**
 * Vue Mixins
 */
    Vue.mixin({
        methods: {
            isAdmin() {
                return isAdmin();
            },

            isClient() {
                return isClient();
            },

            isGuest() {
                return isGuest();
            },

            isCommunityManager() {
                return isCommunityManager();
            },

            isLawyer() {
                if (this.isClient()) {
                    return false;
                }

                return isLawyer();
            },

            isSupplier() {
                if (this.isClient()) {
                    return false;
                }

                return isSupplier();
            },

            getImage(url) {
                const accessToken = this.$auth.accessToken,
                    cacheKey = this.$store.getters['ui/cacheKey'];

                if (!url) {
                    return false;
                }

                // @TODO - Use last modified image date
                if (accessToken) {
                    try {
                        const parsed = new URL(url);
                        parsed.searchParams.append('token', accessToken);
                        parsed.searchParams.append('cache', cacheKey);
                        return parsed.toString();
                    } catch (e) {
                        // In case of relative URL, fallthrough to the original
                        return url;
                    }
                }
            },

            centsToEuros
        }
    });

    /**
     * Vue initialization
     */

    // eslint-disable-next-line no-new
    const vue = window.app = new Vue({
        el: '#app',
        router: Router,
        store: Store,
        i18n,
        render: h => h(App)
    });
    addBoundInterceptors(vue);
    if (!import.meta.env.DEV) {
        const bugsnagVue = Bugsnag.getPlugin('vue');
        bugsnagVue.installVueErrorHandler(Vue);
    }
});
