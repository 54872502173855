
<footer class="footer">
    <ul class="footer__right">
        <li>
            <a
                href="https://www.lawyerlinq.com"
                target="_blank"
                rel="noreferrer noopener"
            >{{ $t('footer.about.label') }}</a>
        </li>
        <li>
            <a
                href="https://www.lawyerlinq.com/privacy-statement"
                target="_blank"
                rel="noreferrer noopener"
            >{{ $t('footer.privacy.label') }}</a>
        </li>
        <li>
            <a href="mailto:moderator@lawyerlinq.com">Contact</a>
        </li>
    </ul>

    <div class="footer__left">
        <img
            src="@/assets/img/logos/lawyerlinq.svg?url"
            alt="Lawyerlinq"
        >
        {{ $t('footer.copyright', { year: (new Date()).getFullYear() }) }}
    </div>
</footer>
