
<div class="confirm-box">
    <div class="confirm-box__intro">
        <div
            v-if="showLogo"
            class="confirm-box__logo"
        >
            <img
                src="@/assets/img/logos/llq-orange.svg?url"
                alt="LawyerlinQ"
            >
        </div>
        <slot name="title">
            <h1 v-if="title">{{ title }}</h1>
        </slot>

        <div
            v-if="hasActions"
            class="confirm-box__actions"
        >
            <slot name="actions" />
        </div>
    </div>

    <div
        v-if="headerTitle && !$slots.header"
        class="confirm-box__header"
    >
        <h2>{{ headerTitle }}</h2>
    </div>

    <div
        v-if="!!$slots.header"
        class="confirm-box__header"
    >
        <slot name="header" />
    </div>

    <article class="confirm-box__body">
        <slot />
    </article>

    <div class="confirm-box__footer">
        <slot name="footer" />

        <template>
            <div class="confirm-box__check">
                <checkbox
                    v-if="canAccept"
                    v-model="isChecked"
                >
                    {{ acceptLabel }}
                </checkbox>

                <div
                    v-if="downloadUrl"
                    class="confirm-box__download"
                >
                    <file-download
                        :attachment="{name: downloadButtonLabel}"
                        :href="downloadUrl"
                    />
                </div>
            </div>

            <v-button
                v-if="canAccept"
                class="btn--secondary"
                :state="requestState"
                :disabled="!isChecked || disabled"
                @click="clickAccept"
            >
                {{ acceptButtonLabel }}
            </v-button>
        </template>
    </div>
</div>
