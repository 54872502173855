'use strict';

import axios from 'axios';

export default {
    loadIcon(context, iconName) {
        const existingIcon = context.getters.icon(iconName),
            requestedIcons = context.getters.requestedIcons;

        if (existingIcon || requestedIcons.includes(iconName)) {
            return false;
        }

        context.commit('requestIcon', iconName);

        axios.get(`${window.appConfig.assetUrl}/build/assets/img/icons/${iconName}.svg`).then((response) => {
            if (!response || !response.data) {
                return false;
            }

            context.commit('icon', {
                name: iconName,
                template: response.data
            });
        });
    },

    /**
     * refreshCacheKey
     *
     * This action sets a new cacheKey based on the current time.
     * The key can be used to cache-bust images.
     *
     * @param {Object} context Store context
     */
    refreshCacheKey(context) {
        const cacheKey = Math.floor(Date.now() / 1000);

        context.commit('cacheKey', cacheKey);
    }
};
